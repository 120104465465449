import {
  ILoggerConfiguration,
  defaultLoggerConfiguration
} from './../models/LoggerConfiguration';
/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { ERROR_CODE } from './ErrorCode';
import { LOG_SOURCE } from './LogSource';
import { AbstractLogger } from './AbstractLogger';
import { LOGGER_TYPE } from './LoggerType';
import { FileLogger } from './FileLogger';
import { ConsoleLogger } from './ConsoleLogger';
import { PremiseLogger } from './PremiseLogger';
import { DaVinciLogger } from './DaVinciLogger';

export * from './LogLevel';
export * from './LogObj';
export * from './ErrorCode';
export * from './LogSource';

/**
 * Logger for AMC's DaVinci Apps. Logs are sent to cloud.
 * Currently there is no way for 3rd party apps to make use of this.
 */
export class Logger extends AbstractLogger {
  private _logger: AbstractLogger;

  constructor(
    logSource: LOG_SOURCE | string,
    isDev = false,
    apiUrl: string = null
  ) {
    super(logSource, isDev, apiUrl);

    // Internal loggers will set their own intervals
    this.clearTimeOutForLogger();
  }

  setConfiguration(config: ILoggerConfiguration) {
    // Clear old timeout of logger already exists because it will be replaced
    if (this._logger) {
      this._logger.clearTimeOutForLogger();
    }

    // Use default if required fields are not defined
    switch (
      config?.['Logger Type']?.toLowerCase() ||
      defaultLoggerConfiguration['Logger Type']
    ) {
      case LOGGER_TYPE.AGENT_PC:
        this._logger = new FileLogger(this.logSource, this.isDev, this.apiUrl);
        break;
      case LOGGER_TYPE.CONSOLE:
        this._logger = new ConsoleLogger(
          this.logSource,
          this.isDev,
          this.apiUrl
        );
        break;
      case LOGGER_TYPE.PREMISE:
        this._logger = new PremiseLogger(
          this.logSource,
          this.isDev,
          // Defaults to api url, utilizes configs in premise logger class
          this.apiUrl
        );
        break;
      case LOGGER_TYPE.DAVINCI:
      default:
        this._logger = new DaVinciLogger(
          this.logSource,
          this.isDev,
          this.apiUrl
        );
    }

    this.config = config;

    this._logger.setConfiguration(config);
  }

  logLoop(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return this._logger.logLoop(message, errorCode, localTime);
  }

  logTrace(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return this._logger.logTrace(message, errorCode, localTime);
  }

  logDebug(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return this._logger.logDebug(message, errorCode, localTime);
  }

  logInformation(
    message: string,
    errorCode?: ERROR_CODE,
    localTime?: Date
  ): void {
    return this._logger.logInformation(message, errorCode, localTime);
  }

  logWarning(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return this._logger.logWarning(message, errorCode, localTime);
  }

  logError(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return this._logger.logError(message, errorCode, localTime);
  }

  logCritical(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return this._logger.logCritical(message, errorCode, localTime);
  }

  pushLogs(): void {
    return this._logger.pushLogs();
  }

  pushLogsAsync(): Promise<void> {
    return this._logger.pushLogsAsync();
  }
}
