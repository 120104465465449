import { ILoggerConfiguration } from '../models/LoggerConfiguration';
import { AbstractLogger } from './AbstractLogger';
import { ERROR_CODE } from './ErrorCode';
import { LOG_SOURCE } from './LogSource';

export class FileLogger extends AbstractLogger {
  constructor(
    logSource: LOG_SOURCE | string,
    isDev: boolean = false,
    apiUrl: string = null
  ) {
    super(logSource, isDev, apiUrl);
  }

  setConfiguration(config: ILoggerConfiguration): void {
    this.config = config;
  }

  logLoop(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return;
  }

  logTrace(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return;
  }

  logDebug(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return;
  }

  logInformation(
    message: string,
    errorCode?: ERROR_CODE,
    localTime?: Date
  ): void {
    return;
  }

  logWarning(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return;
  }

  logError(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return;
  }

  logCritical(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    return;
  }

  pushLogs(): void {
    return;
  }

  pushLogsAsync(): Promise<void> {
    return;
  }
}
