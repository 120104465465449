/**
 * @packageDocumentation
 * @module DaVinci_API
 */
import { sendRequestToFramework, ofLogger } from '../HelperFunctions';
import { OPERATIONS } from '../models/Operations';
import { Logger } from '../Logger';
import { IAppConfiguration } from '../models/AppConfiguration';

/**
 * This should be called after the app has registered for any events it wants to handle.
 * The app will not receive any events until after this is called.
 *
 * This is also needed to get configuration of the app from Creators Studio.
 * Deprecated to get configuration using initializeComplete, instead use getConfig API
 *
 * @param logger The logger that the DaVinci API will use if any error occurs
 * @returns The configuration of the app provided in DaVinci Creators Studio
 */
export function initializeComplete(
  logger?: Logger
): Promise<IAppConfiguration> {
  return sendRequestToFramework(OPERATIONS.INITIALIZE_COMPLETE).then(
    (resolved) => {
      if (logger) {
        ofLogger.apiUrl = logger.apiUrl;
        ofLogger.isDev = logger.isDev;
        ofLogger.logLevel = logger.logLevel;
        ofLogger.logSource = logger.logSource;
        ofLogger.setConfiguration(logger.config);
      }
      return resolved;
    }
  );
}
