import { LOG_SOURCE, Logger } from './Logger/Logger';
import { getSequenceID } from './functions/SequenceId';
import { OPERATIONS } from './models/Operations';
export * from './Logger';

export const ofLogger = new Logger(LOG_SOURCE.OpenFramework, false, null);

let hasSetPostListener = false;
export function setPostListener() {
  hasSetPostListener = true;
  window.addEventListener('message', postMessageListener, false);
}

export function getUrlParameterByName(name: string, url?: string) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*))'),
    results = regex.exec(url);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return null;
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function getCompleteQueryString(): string {
  return location.search;
}

export function safeJSONParse(obj: any) {
  try {
    return JSON.parse(obj);
  } catch (e) {}
  return null;
}

const callbacks: { [id: string]: Function } = {};
const partialCallbacks: { [id: string]: Function } = {};
const registeredEvents: {
  [operation: number]: (...params: any[]) => Promise<any>;
} = {};
export function sendRequestToFramework(
  operation: OPERATIONS,
  data?: any[],
  successCallback?: Function
): Promise<any> {
  if (!hasSetPostListener) {
    setPostListener();
  }

  const result = new Promise((resolve, reject) => {
    const requestId = getSequenceID();
    callbacks[requestId] = (callbackData: any, errors?: any) => {
      if (errors !== undefined) {
        reject(errors);
      } else {
        resolve(callbackData);
      }
    };
    if (successCallback) {
      partialCallbacks[requestId] = successCallback;
    }
    const message = {
      id: requestId,
      operation: operation,
      data: data
    };
    window.parent.postMessage(JSON.stringify(message), '*');
  });
  return result;
}

export function sendRegisterRequestToFramework(
  eventHandler: (...params: any[]) => Promise<any>,
  operation: OPERATIONS,
  data?: any[]
): Promise<void> {
  try {
    if (!hasSetPostListener) {
      setPostListener();
    }
    const result = new Promise<void>((resolve, reject) => {
      const requestId = getSequenceID();
      registeredEvents[operation] = eventHandler;
      callbacks[requestId] = (data: any, errors?: any) => {
        if (errors !== undefined) {
          delete registeredEvents[operation];
          reject(errors);
        } else {
          resolve();
        }
      };
      const message = {
        id: requestId,
        registerOperation: operation,
        data: data
      };
      window.parent.postMessage(JSON.stringify(message), '*');
    });
    return result;
  } catch (e) {
    ofLogger.logError(
      `Exception in sendRegisterRequestToFramework ! exception= ${e.toString()}`
    );
  }
}
function postMessageListener(event: MessageEvent) {
  if (!isJSON(event.data)) {
    return;
  }
  try {
    const message = JSON.parse(event.data);
    if ('isResponse' in message) {
      if (
        message.request &&
        message.request.id &&
        callbacks[message.request.id]
      ) {
        if ('isPartialResponse' in message) {
          if (partialCallbacks[message.request.id]) {
            partialCallbacks[message.request.id](message.resolve);
          }
        } else {
          callbacks[message.request.id](message.resolve, message.reject);
          delete partialCallbacks[message.request.id];
          delete callbacks[message.request.id];
        }
      }
    } else {
      if ('operation' in message && registeredEvents[message.operation]) {
        const promise = registeredEvents[message.operation](...message.data);
        if (promise && promise.then) {
          promise.then(
            (value: any) => sendResponse(message, true, value),
            (value: any) => sendResponse(message, false, value)
          );
        }
      }
    }
  } catch (e) {
    ofLogger.logError(
      'Exception in postMessageListener! exception=' + e.toString()
    );
  }
}
function isJSON(input: any) {
  try {
    JSON.parse(input);
  } catch (e) {
    return false;
  }
  return true;
}
function sendResponse(request: IRequest, resolves: boolean, data: any) {
  const response: IResponse = {
    request: request,
    isResponse: true,
    reject: resolves ? undefined : data,
    resolve: resolves ? data : undefined
  };
  window.parent.postMessage(JSON.stringify(response), '*');
}

export function isResponse(
  message: IRequest | IResponse
): message is IResponse {
  return (
    (message as IResponse).isResponse != null &&
    (message as IResponse).isResponse === true
  );
}

export interface IResponse {
  request: IRequest;
  isResponse: boolean;
  isPartialResponse?: boolean;
  reject: any;
  resolve: any;
}
export interface IRequest {
  id?: string;
  operation: OPERATIONS;
  data: any[];
}
