/**
 * @packageDocumentation
 * @module DaVinci_API
 */
export enum OPERATIONS {
  ADD_CONTEXTUAL_ACCESS_LIST,
  ADD_PLUGIN_IMAGE,
  CHANGE_ICON,
  CHANGE_TITLE,
  CLEAR_CONTEXTUAL_ACCESS_LIST,
  CLICK_TO_DIAL,
  CONTEXTUAL_EVENT,
  DEREGISTER,
  DISPLAY_IFRAME,
  ENABLE_CLICK_TO_DIAL,
  GET_BROWSER_NAME,
  GET_CALL_CENTER_SETTINGS,
  GET_CSS,
  GET_LOGGED_IN,
  GET_PAGE_INFO,
  GET_PARAMS,
  GET_PRESENCE,
  GET_SEARCH_LAYOUT,
  GLOBAL_SEARCH,
  GLOBAL_SEARCH_AND_SCREENPOP,
  INITIALIZE_COMPLETE,
  INTERACTION,
  IS_PLUGIN_VISIBLE,
  IS_TOOLBAR_VISIBLE,
  LOAD_SCRIPT,
  LOGIN,
  LOGOUT,
  ON_FOCUS,
  ON_PRESENCE_CHANGED,
  SAVE_ACTIVITY,
  SCREENPOP_CONTROL_CHANGED,
  SCREEN_POP,
  SEARCH,
  SET_PLUGIN_VISIBLE,
  SET_PRESENCE,
  SET_SOFTPHONE_HEIGHT,
  SET_SOFTPHONE_WIDTH,
  SET_TOOLBAR_ENABLED,
  SET_TOOLBAR_VISIBLE,
  SHOW_CONTROLS,
  USER_INFO,
  CONTEXTUAL_OPERATION,
  GET_USER_DETAILS,
  MY_CALLS_TODAY,
  GET_CONFIG,
  DIALPAD_NUMBER_CLICKED,
  SPEED_DIAL_NUMBER_CLICKED,
  NOTIFICATION_TO_FRAMEWORK,
  SUPPORTED_CHANNEL,
  FRAMEWORK_NOTIFICATIONS,
  GET_APP_NAME,
  INTERCEPT,
  UPDATE_INTERACTION_TO_PROVIDER,
  SET_USER_ATTRIBUTES,
  CLICK_TO_ACT,
  QUEUE_UPDATE,
  QUEUE_ACTION,
  GET_LIVE_AGENTS,
  GET_DAVINCI_AGENT_CONFIG
}
