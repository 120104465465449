/**
 * @packageDocumentation
 * @module DaVinci_API
 */

import { CHANNEL_TYPES } from './ChannelTypes';

/**
 * A contact used by:
 * - [[registerContextualControls]]
 * - [[addContextualContacts]]
 * - [[contextualOperation]]
 */
export interface IContextualContact {
  /**
   * The first name of the user/person to dial
   */
  firstName?: string;
  /**
   * The last name of the user/person to dial
   */
  lastName?: string;
  /**
   * For contacts that DaVinci provides this is set to their davinci username.
   */
  uniqueId: string; // davinci username
  /**
   * A way to group agents.
   * For contacts that DaVinci provides this is set to the name of profile in davinci
   */
  groupName?: string;
  /**
   * The presence of the contact
   * ex: ready, not ready, offline, etc..
   */
  presence?: string;
  /**
   * The contact's reason for a presence
   * ex: Lunch, break, PTO,
   */
  reason?: string;
  /**
   * This is a color(hex, rgb, or other css colors) used to show the presence quickly
   * Ex: green for ready, red for not ready, etc..
   */
  presenceColor?: string;
  channels: IContextualContactChannel[];
  /**
   * This is to provide backward compatibility to [[registerContextualControls]] only. It will be removed soon™
   * @deprecated
   */
  displayName?: string;
}

export interface IContextualContactChannel {
  channelType: CHANNEL_TYPES;
  idName: string;
  id: string;
  createdByApp: string;
  validOperations?: CONTEXTUAL_OPERATION_TYPE[];
  validPresences?: string[];
}

/**
 * The type of operation used by [[contextualOperation]]
 */
export enum CONTEXTUAL_OPERATION_TYPE {
  BlindTransfer,
  WarmTransfer,
  Conference,
  Consult,
  DTMF,
  Other,
  Cancel,
  Answer,
  Reject,
  Hangup,
  Mute,
  Unmute,
  Hold,
  Unhold,
  AddParticipant,
  RemoveParticipant,
  WrapupCompleted,
  SetPresence,
  SetDisposition,
  UpdateCAD
}
