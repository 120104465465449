import { IUserDetails } from './UserDetails';

/**
 * Contains information regarding raw transcript data.
 *
 */
export interface ITranscript {
  id: string;
  data: string;
  isComplete: boolean;
  context: IUserDetails;
  timestamp?: Date;
}

/**
 * Contains information regarding anlytics generated from raw transcript data.
 *
 */
export interface IAnalytics {
  transcript: ITranscript;
  analytics: {
    type: ANALYTICS_TYPES;
    data: any;
  }[];
  timestamp?: Date;
}

/**
 * The type of analytics.
 */
export enum ANALYTICS_TYPES {
  PII,
  Sentiment
}
