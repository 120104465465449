import * as $ from 'jquery';
import { AbstractLogger } from './AbstractLogger';
import { ERROR_CODE } from './ErrorCode';
import { LogObj } from './LogObj';
import { LOG_SOURCE } from './LogSource';
import { ILoggerConfiguration } from '../models/LoggerConfiguration';
import { LOG_LEVEL } from './LogLevel';

/**
 * Logger for AMC's DaVinci Apps. Logs are sent to premise logger only.
 *
 * @export
 * @class PremiseLogger
 * @extends {AbstractLogger}
 */
export class PremiseLogger extends AbstractLogger {
  private static loopBuffer: LogObj[] = [];
  private static traceBuffer: LogObj[] = [];
  private static debugBuffer: LogObj[] = [];
  private static informationBuffer: LogObj[] = [];
  private static warningBuffer: LogObj[] = [];
  private static errorBuffer: LogObj[] = [];
  private static criticalBuffer: LogObj[] = [];
  protected _user: any;

  constructor(
    logSource: LOG_SOURCE | string,
    isDev: boolean = false,
    apiUrl: string = null
  ) {
    super(logSource, isDev, apiUrl);
    this._user = this.syncUserId();
  }

  setConfiguration(config: ILoggerConfiguration): void {
    this.config = config;
  }

  private static getLogBufferReference(logLevel: LOG_LEVEL): LogObj[] {
    switch (logLevel) {
      case LOG_LEVEL.Loop:
        return PremiseLogger.loopBuffer;
      case LOG_LEVEL.Trace:
        return PremiseLogger.traceBuffer;
      case LOG_LEVEL.Debug:
        return PremiseLogger.debugBuffer;
      case LOG_LEVEL.Information:
        return PremiseLogger.informationBuffer;
      case LOG_LEVEL.Warning:
        return PremiseLogger.warningBuffer;
      case LOG_LEVEL.Error:
        return PremiseLogger.errorBuffer;
      case LOG_LEVEL.Critical:
        return PremiseLogger.criticalBuffer;
      default:
        return [];
    }
  }

  private logMessage(
    methodLogLevel: LOG_LEVEL,
    message: string,
    errorCode: ERROR_CODE,
    localTime: Date
  ): void {
    const logObj = this.generateLogObj(
      methodLogLevel,
      errorCode,
      message,
      localTime
    );
    PremiseLogger.getLogBufferReference(methodLogLevel).push(logObj);
  }

  logLoop(message: string, errorCode: ERROR_CODE, localTime: Date): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Loop
    ) {
      this.logMessage(LOG_LEVEL.Loop, message, errorCode, localTime);
    }
  }

  logTrace(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Trace
    ) {
      this.logMessage(LOG_LEVEL.Trace, message, errorCode, localTime);
    }
  }

  logDebug(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Debug
    ) {
      this.logMessage(LOG_LEVEL.Debug, message, errorCode, localTime);
    }
  }

  logInformation(
    message: string,
    errorCode?: ERROR_CODE,
    localTime?: Date
  ): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Information
    ) {
      this.logMessage(LOG_LEVEL.Information, message, errorCode, localTime);
    }
  }

  logWarning(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Warning
    ) {
      this.logMessage(LOG_LEVEL.Warning, message, errorCode, localTime);
    }
  }

  logError(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Error
    ) {
      this.logMessage(LOG_LEVEL.Error, message, errorCode, localTime);
    }
  }

  logCritical(message: string, errorCode?: ERROR_CODE, localTime?: Date): void {
    if (
      this.config['Premise Logger URL'] &&
      this.config['Log Level'] <= LOG_LEVEL.Critical
    ) {
      this.logMessage(LOG_LEVEL.Critical, message, errorCode, localTime);
    }
  }

  public pushLogs(): void {
    if (!this.apiUrl) {
      return;
    }
    this.postBuffers(LOG_LEVEL.Loop);
    this.postBuffers(LOG_LEVEL.Trace);
    this.postBuffers(LOG_LEVEL.Debug);
    this.postBuffers(LOG_LEVEL.Information);
    this.postBuffers(LOG_LEVEL.Warning);
    this.postBuffers(LOG_LEVEL.Error);
    this.postBuffers(LOG_LEVEL.Critical);
  }

  private postBuffers(logLevel: LOG_LEVEL): void {
    const loggerEndpoint: string = this.getLoggerEndpoint(logLevel);
    const logBuffer: LogObj[] = PremiseLogger.getLogBufferReference(logLevel);
    if (logBuffer.length > 0) {
      $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: loggerEndpoint,
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: JSON.stringify(logBuffer)
      });
      logBuffer.splice(0, logBuffer.length);
    }
  }

  public async pushLogsAsync(): Promise<void> {
    if (!this.apiUrl) {
      return;
    }
    await this.postBuffersAsync(LOG_LEVEL.Loop);
    await this.postBuffersAsync(LOG_LEVEL.Trace);
    await this.postBuffersAsync(LOG_LEVEL.Debug);
    await this.postBuffersAsync(LOG_LEVEL.Information);
    await this.postBuffersAsync(LOG_LEVEL.Warning);
    await this.postBuffersAsync(LOG_LEVEL.Error);
    await this.postBuffersAsync(LOG_LEVEL.Critical);
  }

  private async postBuffersAsync(logLevel: LOG_LEVEL): Promise<void> {
    const loggerEndpoint: string = this.getLoggerEndpoint(logLevel);
    const logBuffer: LogObj[] = PremiseLogger.getLogBufferReference(logLevel);
    if (logBuffer.length > 0) {
      await $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: loggerEndpoint,
        async: false,
        xhrFields: {
          withCredentials: true
        },
        type: 'POST',
        data: JSON.stringify(logBuffer)
      });
      logBuffer.splice(0, logBuffer.length);
    }
  }

  private getLoggerEndpoint(logLevel: LOG_LEVEL): string {
    switch (logLevel) {
      case LOG_LEVEL.Loop:
        return `${this.config['Premise Logger URL']}/api/Logger/LogLoop/${this._logSource}/${this._logLevel}/${this._user}`;
      case LOG_LEVEL.Trace:
        return `${this.config['Premise Logger URL']}/api/Logger/LogTrace/${this._logSource}/${this._logLevel}/${this._user}`;
      case LOG_LEVEL.Debug:
        return `${this.config['Premise Logger URL']}/api/Logger/LogDebug/${this._logSource}/${this._logLevel}/${this._user}`;
      case LOG_LEVEL.Information:
        return `${this.config['Premise Logger URL']}/api/Logger/LogInformation/${this._logSource}/${this._logLevel}/${this._user}`;
      case LOG_LEVEL.Warning:
        return `${this.config['Premise Logger URL']}/api/Logger/LogWarning/${this._logSource}/${this._logLevel}/${this._user}`;
      case LOG_LEVEL.Error:
        return `${this.config['Premise Logger URL']}/api/Logger/LogError/${this._logSource}/${this._logLevel}/${this._user}`;
      case LOG_LEVEL.Critical:
        return `${this.config['Premise Logger URL']}/api/Logger/LogCritical/${this._logSource}/${this._logLevel}/${this._user}`;
      default:
        return `${this.config['Premise Logger URL']}/api/Logger/LogTrace/${this._logSource}/${this._logLevel}/${this._user}`;
    }
  }

  /**
   * This method will sync the logLevel with account level log level of the logged in user.
   * User has to be logged in before calling this method.
   */
  protected syncUserId(): void {
    if (this.apiUrl) {
      $.ajax({
        headers: {
          'Content-Type': 'application/json'
        },
        url: `${this.apiUrl}/api/me`,
        xhrFields: {
          withCredentials: true
        },
        type: 'GET',
        success: (user: any) => {
          if (user !== undefined && user != null) {
            this._user = user.userid;
          }
        }
      });
    }
  }
}
